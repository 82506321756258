<template lang="pug">
  .app-select(
    :class="[appSelectSize, { opened }]"
    :data-placement="tippyPlacement"
  )
    slot(:opened="opened")
    template(v-if="onlyBody")
      DropdownBody(
        v-bind="$props"
        v-on="$listeners"
        :selected-items="selectedItems"
      )
    template(v-else)
      AppTippy(
        v-if="isMounted"
        v-bind="tippyProps"
        ref="tippy"
        :disabled="disabled"
        @out-of-boundaries="handleOutOfBoundaries"
        @flip="handleFlip"
        @hide="handleHideDropdown"
        @show="handleShowDropdown"
        @shown="handleShownDropdown"
      )
        template(v-slot:target)
          .btn.dropdown-toggle(
            :class="{ disabled }"
          )
            .app-select-dropdown
              template(v-if="useRemovableLabels")
                span.app-select-label.empty(
                  v-if="noSelectedItems"
                  :title="placeholder"
                ) {{ placeholder }}
                RemovableLabels(
                  v-else
                  :inverted="opened"
                  :selected-items="selectedItems"
                  :title-key="titleKey"
                  @remove="handleRemovableLabelClick"
                )
              template(v-else)
                span.app-select-label(
                  :class="{ empty: selectedItemsTitle === placeholder }"
                  :title="selectedItemsTitle"
                ) {{ selectedItemsTitle }}
                span.app-select-label-helper(v-if="andOtherHelper") {{ andOtherHelper }}
              FaIcon.app-select-icon(
                :icon="icon"
                :spin="loading"
                :class="{ opened }"
              )

        template(v-slot:content)
          DropdownBody(
            ref="dropdownBody"
            v-bind="$props"
            v-on="$listeners"
            :opened="opened"
            :selected-items="selectedItems"
            @hide-dropdown="closeTippy"
            :max-select-count="maxSelectCount"
          )
</template>

<script>
  // mixins
  import withLocale from "@/mixins/withLocale"

  import AppTippy from "@/components/elements/AppTippy"
  import DropdownBody from "./Body"
  import RemovableLabels from "./RemovableLabels"

  // misc
  import { isEmpty } from "lodash-es"

  export default {
    props: {
      appendTo: {
        type: [HTMLElement, String],
        default: () => document.body
      },
      followReferenceBoundary: {
        type: Boolean,
        default: true
      },
      zIndex: {
        type: [String, Number],
        default: 10000001 // z-index of AppOverlayLoader + 1
      },
      lazy: {
        type: Boolean,
        default: true
      },
      lazyScroll: {
        type: Boolean,
        default: false
      },
      name: {
        type: String
      },
      onlyBody: {
        type: Boolean,
        default: false
      },
      batchSelect: {
        type: Boolean,
        default: false
      },
      checkbox: {
        type: Boolean,
        default: false
      },
      editable: {
        type: Boolean,
        default: false
      },
      autofocus: {
        type: Boolean,
        default: true
      },
      deletable: {
        type: Boolean,
        default: false
      },
      value: {
        type: [Array, Object],
        default: () => new Array()
      },
      items: {
        type: Array,
        default: () => new Array()
      },
      vueKey: {
        default() {
          return item => item[this.valueKey]
        }
      },
      valueKey: {
        type: String,
        default: "id"
      },
      titleKey: {
        type: String,
        default: "name"
      },
      subtitleKey: {
        type: String,
        default: "subtitle"
      },
      placeholder: {
        type: String,
        default() {
          return this.$t("components.checkboxes_group.nothing_selected")
        }
      },
      noDataPlaceholder: {
        type: String,
        default() {
          return this.$t("no_data")
        }
      },
      searchable: {
        type: Boolean,
        default: false
      },
      searchPlaceholder: {
        type: String,
        default() {
          return this.$t("components.select.search")
        }
      },
      tips: String,
      multiple: {
        type: Boolean,
        default: false
      },
      creatable: {
        type: Boolean,
        default: false
      },
      closeOnSelect: {
        type: Boolean,
        default: false
      },
      allowEmpty: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      expanded: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isItemDisabled: {
        // TODO: investigate and refactore
        type: Function,
        default: () => false
      },
      orderDirection: {
        type: String,
        default: "asc",
        validator: value => ["preorder", "keep", "asc", "desc"].includes(value)
      },
      menuClass: {
        type: String,
        default: ""
      },
      size: {
        type: String,
        default: "medium",
        validator: value => ["small", "medium", "large"].includes(value)
      },
      removableLabels: {
        type: Boolean,
        default: false
      },
      draggableSelected: {
        type: Boolean,
        default: false
      },
      maxSelectCount: {
        type: Number
      },
      isCloseOnClickOutside: {
        type: Boolean,
        default: true
      },
      withSearchIcon: {
        type: Boolean,
        default: false
      },
      placement: {
        type: String,
        default: "bottom"
      }
    },

    mixins: [withLocale],

    components: {
      RemovableLabels,
      DropdownBody,
      AppTippy
    },

    data() {
      return {
        opened: false,
        tippyPlacement: "",
        tippyOutOfBoundaries: false,
        isMounted: false
      }
    },

    mounted() {
      this.isMounted = true
    },

    computed: {
      noSelectedItems() {
        return isEmpty(this.selectedItems)
      },

      useRemovableLabels() {
        return this.removableLabels && this.allowEmpty && this.multiple
      },

      appSelectSize() {
        return this.useRemovableLabels ? "stretch" : this.size
      },

      tippyProps() {
        return {
          lazy: this.lazy,
          maxWidth: 1000,
          theme: "dropdown-menu-theme",
          appendTo: this.appendTo,
          followReferenceBoundary: this.followReferenceBoundary,
          zIndex: Number(this.zIndex),
          hideOnClick: this.isCloseOnClickOutside,
          placement: this.placement
        }
      },

      tippyEl() {
        return this.$refs.tippy.$tippy.popper.querySelector(".tippy-box")
      },

      icon() {
        return this.loading ? "spinner" : "chevron-down"
      },

      selectedItems() {
        if (this.draggableSelected) {
          return this.selectedItemsFromValue
            .map((el, order) => (el.order === undefined ? { ...el, order } : el))
            .sort((a, b) => a.order - b.order)
        }

        return this.selectedItemsFromValue
      },

      selectedItemsFromValue() {
        if (isEmpty(this.value)) {
          return []
        } else {
          return [this.value].flat()
        }
      },

      andOtherHelper() {
        // Leave null if count of items is equal to the following values
        if ([0, 1, this.items.length].includes(this.selectedItems.length)) return null

        return `${this.$t("components.checkboxes_group.and_other")} (${this.selectedItems.length})`
      },

      selectedItemsTitle() {
        if (this.selectedItems.length === 0) {
          return this.placeholder
        }

        if (this.selectedItems.length === this.items.length && this.items.length !== 1) {
          return this.$t("components.dropdown.all_selected")
        }

        const title = this.selectedItems[0][this.titleKey]
        const subtitle = this.selectedItems[0][this.subtitleKey]
        const fullTitle = title && subtitle ? [title, subtitle].join(" ") : title
        return fullTitle
      }
    },

    methods: {
      handleRemovableLabelClick(item) {
        const selectedItems = [...this.selectedItems]
        const index = this.selectedItems.indexOf(item)

        selectedItems.splice(index, 1)

        this.$emit("remove", item, index)
        this.$emit("select", selectedItems)
      },

      closeTippy() {
        this.$refs.tippy.$tippy.hide()
      },

      handleFlip(placement) {
        this.tippyPlacement = placement
      },

      handleOutOfBoundaries(value) {
        this.tippyOutOfBoundaries = value
      },

      handleHideDropdown() {
        setTimeout(() => (this.opened = false), 100)
        this.$emit("closed")
      },

      handleShownDropdown() {
        this.scrollToSelected()
        this.$emit("opened", this.$el)
      },

      handleShowDropdown() {
        if (this.disabled) {
          return false
        } else {
          this.opened = true
        }
      },

      scrollToSelected() {
        if (this.noSelectedItems) return

        if (!this.lazyScroll) {
          const el = this.$refs.dropdownBody.$el.querySelector(".app-select-item.selected")

          el !== null && this.scrollToView(el, el.parentNode)
        }
      },

      scrollToView(el, container) {
        const { bottom, top } = el.getBoundingClientRect()
        const parentRect = container.getBoundingClientRect()

        const isOutside = top <= parentRect.top || bottom >= parentRect.bottom

        isOutside && el.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .app-select
    padding: 6px
    background: $default-white
    border-radius: 5px
    border: 1px solid transparent
    transition: box-shadow 0.2s ease-in-out
    width: 100%

    &:not(.opened)
      .dropdown-toggle:not(.disabled):hover
        box-shadow: 0 2px 10px -8px $default-black

    &.large
      .dropdown-toggle
        height: 38px

      *
        font-size: 1rem

    &.medium
      *
        font-size: 0.85rem

      .dropdown-toggle
        height: 34px

    &.small
      *
        font-size: 0.7rem
      .dropdown-toggle
        height: 27px

    &.stretch
      height: auto
      min-height: 36px

      .dropdown-toggle
        min-height: 34px

      ::v-deep
        .btn
          padding: 0.1rem 0.3rem

    &.invalid
      ::v-deep
        .dropdown-toggle
          +default-invalid-input

    &.opened
      border: 1px solid $default-gray-light

      &[data-placement^="bottom"]
        border-radius: 5px 5px 0px 0px
        box-shadow: 0 0px 20px -16px $default-black !important

      &[data-placement^="top"]
        border-radius: 0px 0px 5px 5px
        box-shadow: 0px 0px 20px -16px $default-black !important

      ::v-deep
        .dropdown-toggle
          background: $default-gray-light

    .dropdown-toggle
      width: 100%
      background: white
      border: 1px solid $border-element-color
      color: $default-gray
      display: flex
      align-items: center
      justify-content: center
      transition: all 0.2s ease-in-out

      &.disabled
        opacity: 0.5
        cursor: not-allowed

      &::after
        display: none

      &:focus, &:active
        outline: none !important
        box-shadow: none !important

    &-dropdown
      display: flex
      width: 100%
      color: $default-black
      justify-content: space-between
      align-items: center
      gap: 0.5rem

    &.inactive
      span, svg
        color: $default-black-inactive

    &-label
      line-height: normal
      overflow: hidden
      text-overflow: ellipsis
      text-align: left

      &-helper
        margin-right: auto
        line-height: normal

      &.empty
        opacity: 0.5

    &-icon
      color: $dropdown-toggle-color
      transition: transform 0.3s ease-in-out

      &.opened
        transform: rotate(180deg)
</style>

<style lang="sass">
  @import "@/assets/styles/variables.sass"

  .tippy-popper
    z-index: 10000001 !important // z-index of AppOverlayLoader + 1

  [data-theme="dropdown-menu-theme"]
    margin: 0 -7px
    border-top: 0 !important
    border-radius: 0 0 5px 5px
    background: $default-white
    color: $default-black
    border: 1px solid $default-gray-light
    padding: 0
    min-width: 3rem !important
    width: auto
    box-shadow: 0 4px 20px -18px $default-black !important

    &[data-reference-hidden]
      border-top: 1px solid $default-gray-light !important
      border-bottom: 1px solid $default-gray-light !important
      border-radius: 5px

    &[data-placement^="top"]
      border-bottom: none
      border-top: 1px solid $default-gray-light !important
      border-radius: 5px 5px 0 0
      box-shadow: 0 -5px 20px -18px $default-black !important

      .app-select-body
        padding-top: 6px !important

    &:focus
      outline: none !important

    .tippy-content
      padding: 0
</style>
